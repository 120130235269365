// src/components/Register.js
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import '../../styles/register.scss';
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';


const Register = () => {
  const [formData, setFormData] = useState({ name: '', email: '', password: '', role: 'Parent' });
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Handle form submission
    // Set response message
    register(formData).then(response => {

      if (response.success) {
        toast.success(response.msg, { autoClose: 2000 });
        navigate('/login');
      } else {
        toast.error(response.msg, { autoClose: 2000 });
      }
    })
      .finally(() => {
        setLoading(false); // Hide spinner
      });

    // setResponseMessage('Registration successful! Please check your email for verification.');
  };
  const handleRoleChange = (e) => {
    setFormData({ ...formData, role: e.value });
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <Link to="/">
          <img
            alt="logo"
            src="/logo.png"
            height="60"
            className="logo"
          />
        </Link>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter your name"
              className="full-width"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              className="full-width"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Password
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter your password"
              className="full-width"
              inputStyle={{ width: '100%' }}
              required
            />
          </div>
          <div className="form-group">
            <label>Register As</label>
            <div className="radio-group">
              <RadioButton
                inputId="parent"
                name="role"
                value="Parent"
                onChange={handleRoleChange}
                checked={formData.role === 'Parent'}
              />
              <label htmlFor="parent" className="radio-label">Parent</label>

              <RadioButton
                inputId="therapist"
                name="role"
                value="Therapist"
                onChange={handleRoleChange}
                checked={formData.role === 'Therapist'}
              />
              <label htmlFor="therapist" className="radio-label">Therapist</label>
            </div>
          </div>
          <Button label="Register" className="btn-primary full-width" />
          {loading && (
            <div style={spinnerContainerStyle}>
              <ProgressSpinner />
            </div>
          )}
        </form>
        <div className="response-message">{responseMessage}</div>
        <div className="register-footer">
          <small>
            Already have an account? <Link to="/login">Login here</Link>
          </small>
          <br></br>
          <small>
            By registering, you agree to our <Link to="/terms">Terms and Conditions</Link>.
          </small>
        </div>
      </div>
    </div>
  );

};
const spinnerContainerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
};

export default Register;
