import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const VerifyEmail = () => {
    const { token } = useParams(); // Get the token from the URL
    const [status, setStatus] = useState('Verifying...');
    const navigate = useNavigate();
    useEffect(() => {
        console.log("verification component")
        // Function to verify the email
        const verifyEmail = async () => {
            try {
                const response = await axios.post('/api/auth/verify-email', { token });
                if (response.data.success) {
                    setStatus('Your email has been verified successfully!');
                    setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
                } else {
                    setStatus('Verification failed. Please try again.');
                }
            } catch (error) {
                console.log(error);
                setStatus('An error occurred. Please try again.');
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <div>
            <h2>{status}</h2>
        </div>
    );
};

export default VerifyEmail;
