import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

const PersonalDetails = ({ userDetails, handleChange }) => {
    return (
        <div>
            {/* Name */}
            <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <InputText
                        id="name"
                        name="name"
                        value={userDetails.name}
                        onChange={handleChange}
                    />
            </div>
            {/* Phone */}
            <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <InputText
                    id="phone"
                    name="phone"
                    value={userDetails.phone}
                    onChange={handleChange}
                />
            </div>

            {/* Address */}
            <div className="form-group">
                <label htmlFor="address">Address</label>
                <InputTextarea
                    id="address"
                    name="address"
                    value={userDetails.address}
                    onChange={handleChange}
                />
            </div>

            {/* Add more fields as necessary */}
        </div>
    );
};

export default PersonalDetails;
