import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { AbilityProvider } from './contexts/AbilityContext';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import VerifyEmail from './components/Auth/VerifyEmail';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import UsersList from './components/User/UsersList';
import Home from './components/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Admin from './components/Admin';
// import User from './components/User';
import ProtectedRoute from './components/ProtectedRoute';
import UserProfile from './components/User/UserProfile';
import Layout from './components/Layout';
import Settings from './components/Settings';
import RolesList from './components/User/RolesList';
import ServicesList from './components/Service/ServicesList';
import PlansList from './components/Plan/PlansList';
import SubscriptionsList from './components/Subscription/SubscriptionsList';
import PaymentsList from './components/Payment/PaymentsList';
import DeviceBrandsList from './components/Device/DeviceBrandsList';
import DeviceModelsList from './components/Device/DeviceModelsList';
import ChildrenList from './components/Child/ChildrenList';

function App() {
  return (
    <AbilityProvider>
      <AuthProvider>
        <Router>
          <div className="App">
            <ToastContainer />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/verify-email/:token" element={<VerifyEmail />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />

              {/* <ProtectedRoute path="/admin" roles={['admin']} component={Admin} />
          <ProtectedRoute path="/user" roles={['admin', 'user']} component={User} /> */}
              {/* <Route path="/profile" element={<ProtectedRoute element={UserProfile} />} /> */}
              <Route path="/index" element={<ProtectedRoute element={Layout} />} >
                <Route path="/index/profile" element={<UserProfile />} />
                <Route path="/index/settings" element={<Settings />} />
                <Route path="/index/users" element={<UsersList />} />
                <Route path="/index/roles" element={<RolesList />} />
                <Route path="/index/services" element={<ServicesList />} />
                <Route path="/index/plans" element={<PlansList />} />
                <Route path="/index/subscriptions" element={<SubscriptionsList />} />
                <Route path="/index/payments" element={<PaymentsList />} />
                <Route path="/index/devicebrands" element={<DeviceBrandsList />} />
                <Route path="/index/devicemodels" element={<DeviceModelsList />} />
                <Route path="/index/children" element={<ChildrenList />} />
              </Route>
              {/* <Route path="/settings" element={<ProtectedRoute element={Layout<Settings>} />} /> */}
              {/* <Route path="/index" element={<Layout />} /> */}
              {/* <Route path="/profile" element={<ProtectedRoute component={Profile} />} /> */}

            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </AbilityProvider>
  );
}

export default App;
