import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';


const SingleService = ({ service, setService, onSubmit, isEdit }) => {

  const serviceTypes = [
    { label: 'Hourly', value: 'hourly' },
    { label: 'One Time', value: 'one_time' },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setService((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  return (
    <div className="form-group">
      <div className="p-fluid">

        <div className="p-field">
          <label htmlFor="name">Service Name</label>
          <InputText
            id="name"
            name="name"
            value={service.name}
            onChange={handleChange}
            required
          />
        </div>


        <div className="p-field">
          <label htmlFor="serviceType">Service Type</label>
          <Dropdown
            id="serviceType"
            name="serviceType"
            value={service.serviceType}
            options={serviceTypes}
            onChange={handleChange}
            placeholder="Select a Service Type"
            required
          />
        </div>

        <div className="p-field">
          <label htmlFor="onlineFee">Online Fee</label>
          <InputNumber
            id="onlineFee"
            name="onlineFee"
            value={service.onlineFee}
            onValueChange={handleChange}
            mode="currency"
            currency="PKR"
          />
        </div>

        <div className="p-field">
          <label htmlFor="onsiteFee">Onsite Fee</label>
          <InputNumber
            id="onsiteFee"
            name="onsiteFee"
            value={service.onsiteFee}
            onValueChange={handleChange}
            mode="currency"
            currency="PKR"
            locale="en-US"
          />
        </div>

        <div className="p-field">
          <label htmlFor="details">Details</label>
          <InputTextarea
            id="details"
            name="details"
            value={service.details}
            onChange={handleChange}
            rows={3}
            cols={10}
          />
        </div>
        <Button
          label={isEdit ? 'Update Service' : 'Add Service'}
          className='btn-primary mt-2'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SingleService;
