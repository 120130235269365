import React, { useState, useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { toast } from 'react-toastify'
import DynamicDataTable from '../Common/DataListing';
import SingleUser from './SingleUser';
// import SummaryCard from '../Common/SummaryCards';
import { getUsers, addUser, editUser, deleteUser } from '../../api/userApi';
import { getServices } from '../../api/serviceApi';

// import './UsersList.css'; // Import CSS file


const UsersList = () => {
    const [usersData, setUsersData] = useState([]); //to be used for DataListing
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        roleId: '',
        isActive: false,
    });  //to be used for SingleUser (Add/Edit)
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [isTherapistServicesModalVisible, setIsTherapistServicesModalVisible] = useState(false);

    const renderIsActiveSwitch = (rowData) => {
        return (
            <InputSwitch
                checked={rowData.isActive}
                onChange={(e) => handleInlineEdit(rowData.id, { 'isActive': e.value })}
            />
        );
    };
    const renderEmailVerifiedSwitch = (rowData) => {
        return (
            <InputSwitch
                checked={rowData.emailVerified}
                onChange={(e) => handleInlineEdit(rowData.id, { 'emailVerified': e.value })}
            />
        );
    };

    const renderUserRole = (rowData) => {

        return <span>{rowData.role.name}</span>;
    };

    const [columns, setColumns] = useState([
        { field: 'name', header: 'Name' },
        { field: 'email', header: 'Email' },
        { field: 'isActive', header: 'Is Active', body: renderIsActiveSwitch },
        { field: 'emailVerified', header: 'Email Verified?', body: renderEmailVerifiedSwitch },
        { header: 'Role', body: renderUserRole },
    ]);

    useEffect(() => {
        fetchUsersData();
        fetchServices();
    }, []);

    const fetchUsersData = async () => {
        const data = await getUsers();
        setUsersData(data);
    };

    const fetchServices = async () => {
        try {
            const response = await getServices();
            setServices(response);
        } catch (error) {
            console.error('Error fetching services', error);
        }
    };

    // const handleCreate = async (userData) => {
    //     await addUser(userData);
    //     fetchUsersData();
    // };

    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleInlineEdit = async (userId, userData) => {
        await editUser(userId, userData);
        fetchUsersData();
    };
    const handleCreate = () => {
        setIsEditing(false);
        setUserData({
            name: '',
            email: '',
            roleId: '',
            password: '',
            isActive: false,
        });
        setIsModalVisible(true);
    };

    const handleEdit = (rowData) => {
        setIsEditing(true);
        console.log("user rowData", rowData);
        setUserData(rowData); // Populate form with selected row data
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        let response = {}
        if (isEditing) {
            response = await editUser(userData.id, userData); // Update the user
        } else {
            response = await addUser(userData); // Create a new user
        }
        responseAlert(response, 300);
        setIsModalVisible(false);
        fetchUsersData() // Refresh the data in the table
    };

    const handleDelete = async (userId) => {
        await deleteUser(userId);
        fetchUsersData();
    };

    const handleServiceChange = (newServices) => {
        console.log("newServices",newServices)
        setSelectedServices(newServices);
        console.log("onchange services",selectedServices);
        setUserData(prevData => ({ ...prevData, services: newServices }));
    };

    const handleTherapistServices = async (rowData) => {
        
        setUserData(rowData)
        setSelectedServices(rowData.assignedServices)
        setIsTherapistServicesModalVisible(true);
    };
    
    const updateTherapistServices = async () => {
     
        let response = await editUser(userData.id, userData); // Update the user
        responseAlert(response, 300);
        setIsTherapistServicesModalVisible(false);
        fetchUsersData();
    };

    const therapistServicesAction = [
        {
            // icon: 'pi pi-user-edit',
            label: 'Therapist Services',
            className: 'btn-outline-primary',
            handler: handleTherapistServices,
            condition: (rowData) => rowData.roleId === 4
        }
    ];


    return (
        <div>
            {/* <div className="summary-cards-container">
                <SummaryCard title="Total Users" value={usersData.length} />
                <SummaryCard title="Admins" value={usersData.filter(user => user.role === 'Admin').length} />
                <SummaryCard title="Therapists" value={usersData.filter(user => user.role === 'Therapist').length} />
                <SummaryCard title="Parents" value={usersData.filter(user => user.role === 'Parent').length} />
            </div> */}
            <DynamicDataTable
                data={usersData}
                subject={"User"}
                columns={columns}
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete}
                extraActions={therapistServicesAction}
            />
            <Dialog
                header={isEditing ? "Edit User" : "Add New User"}
                visible={isModalVisible}
                style={{ width: '30vw' }}
                className='form-box'
                onHide={() => setIsModalVisible(false)}
            >
                <SingleUser
                    user={userData}
                    setUser={setUserData}
                    onSubmit={handleSubmit}
                    isEdit={isEditing}
                />
            </Dialog>
            <Dialog
                header="Therapist Services"
                visible={isTherapistServicesModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsTherapistServicesModalVisible(false)}>

                <div className="form-group">
                    <div className="p-field">
                        <MultiSelect
                            id='therapistServices'
                            value={selectedServices || []}
                            options={services.map((service) => ({
                                label: `${service.name}`,
                                value: service,
                            }))}
                            onChange={(e) => handleServiceChange(e.value)}
                            optionLabel="label"
                            optionValue="value"
                            display="chip"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className='mt-2'>
                        <Button label="Submit" className='full-width btn-primary' icon="pi pi-check" onClick={() => updateTherapistServices()} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default UsersList;
