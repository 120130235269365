import axios from 'axios';

const API_URL = '/api/roles';

// Fetch all roles
export const getRoles = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

// Fetch a single role by ID
export const getRoleById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

// Create a new role
export const createRole = async (roleData) => {
    const response = await axios.post(API_URL, roleData);
    return response.data;
};

// Update an existing role
export const updateRole = async (id, roleData) => {
    // console.log("roleData",roleData);
    const response = await axios.put(`${API_URL}/${id}`, roleData);
    return response.data;
};

// Delete a role
export const deleteRole = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getRolePermissions = async (roleId) => {
    try {
        const response = await axios.get(`${API_URL}/${roleId}/permissions`);
        return response.data; // Assuming the API returns the grouped permissions data directly
    } catch (error) {
        console.error('Error fetching role permissions', error);
        throw error;
    }
};