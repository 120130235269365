import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getPlanById } from '../../api/planApi';
import { getServices } from '../../api/serviceApi';

const SinglePlan = ({ plan, setPlan, onSubmit, isEdit }) => {

    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState(plan?.services || []);
    // selectedServices.map((element, index) => {
    //     console.log(element,index);
    // });
    // console.log("selectedServices",selectedServices);
    console.log("all services",services);
    // console.log(plan);
    useEffect(() => {
        // Fetch all services
        const fetchServices = async () => {
            try {
                const response = await getServices();
                setServices(response);
            } catch (error) {
                console.error('Error fetching services', error);
            }
        };

        fetchServices();
        if (selectedServices.length === 0) {
            addServiceRow();
        }
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleServiceChange = (serviceId, index) => {
        const updatedServices = [...selectedServices];
        const service =services.find(service => service.id === serviceId);
        // console.log("on change service",service);
        updatedServices[index] = { ...updatedServices[index], service };
        setSelectedServices(updatedServices);
        setPlan(prevPlan => ({ ...prevPlan, services: updatedServices }));
    };
    const handleFieldChange = (value, index, field) => {
        const updatedServices = [...selectedServices];
        updatedServices[index] = { ...updatedServices[index], [field]: value };
        setSelectedServices(updatedServices);
        setPlan(prevPlan => ({ ...prevPlan, services: updatedServices }));
    };



    const addServiceRow = () => {
        setSelectedServices([...selectedServices, { service: null, daysPerMonth: null, hoursPerDay: null }]);
    };
    const removeServiceRow = (index) => {
        if (selectedServices.length > 1) {
            const updatedServices = [...selectedServices];
            updatedServices.splice(index, 1);
            setSelectedServices(updatedServices);
        }
    };

    return (
        <div className='form-grouo'>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="name">Plan Name</label>
                    <InputText
                        id="name"
                        name="name"
                        value={plan.name}
                        onChange={handleChange} />
                </div>
                <div className="p-field">
                    <label htmlFor="details">Details</label>
                    <InputText
                        id="details"
                        name="details"
                        value={plan.details}
                        onChange={handleChange} />
                </div>
                {/* <div className="p-field">
                    <label htmlFor="onlineFee">Online Fee</label>
                    <InputNumber
                        id="onlineFee"
                        name="onlineFee"
                        value={onlineFee}
                        onValueChange={handleChange} />
                </div>
                <div className="p-field">
                    <label htmlFor="onsiteFee">Onsite Fee</label>
                    <InputNumber
                        id="onsiteFee"
                        name="onsiteFee"
                        value={onsiteFee}
                        onValueChange={handleChange} />
                </div> */}
                <div className="p-field">
                    <label>Services</label>
                    {selectedServices.map((serviceEntry, index) => (

                        // className="p-field p-grid"
                        <div key={index} className="p-grid p-align-center mt-1 mb-5">
                            <div className="p-col-3 mt-2 mb-1">
                                <Dropdown
                                    value={serviceEntry.service?.id}
                                    options={services}
                                    onChange={(e) => handleServiceChange(e.value, index)}
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Select a Service"
                                />
                            </div>
                            <label>Days/Month</label>
                            <div className="p-col-2 mt-2 mb-1">
                                <InputNumber
                                    placeholder="Days per Month"
                                    value={serviceEntry.daysPerMonth}
                                    max={31}
                                    onChange={(e) => handleFieldChange(e.value, index, 'daysPerMonth')}
                                />
                            </div>
                            <label>Hours/Day</label>
                            <div className="p-col-2 mt-2 mb-1">
                                <InputNumber
                                    placeholder="Hours per Day"
                                    value={serviceEntry.hoursPerDay}
                                    max={24}
                                    onChange={(e) => handleFieldChange(e.value, index, 'hoursPerDay')}
                                />
                            </div>
                            <div className="p-col-2 p-d-flex p-jc-center p-ai-center" style={{ float: 'right' }}>
                                <Button
                                    icon="pi pi-plus"
                                    className="p-button-sm p-button-rounded p-button-success"
                                    onClick={addServiceRow}
                                    style={{ marginRight: '5px' }}
                                />
                                <Button
                                    icon="pi pi-minus"
                                    className="p-button-sm p-button-rounded p-button-danger"
                                    onClick={() => removeServiceRow(index)}
                                />
                            </div>
                        </div>
                    ))}
                    {/* <Button label="Add Service" icon="pi pi-plus" onClick={addServiceRow} /> */}
                </div>
                <Button
                    label={isEdit ? 'Update Plan' : 'Add Plan'}
                    className='btn-primary mt-2'
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

export default SinglePlan;
