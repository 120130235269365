import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { createDeviceModel, getDeviceModels, updateDeviceModel, deleteDeviceModel } from '../../api/deviceModelApi';
import SingleDeviceModel from './SingleDeviceModel';
import DataListing from '../Common/DataListing';
import { Dialog } from 'primereact/dialog';


const DeviceModelsList = () => {
    const [deviceModels, setDeviceModels] = useState([]);
    const [deviceModelData, setDeviceModelData] = useState({
        name: '',
        DeviceBrandId: null,
        
    });  //to be used for SingleDeviceModel (Add/Edit)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const renderdeviceBrand = (rowData) => {
        return (<span>{rowData.deviceBrand.name}</span>)
    };
   
    const [columns, setColumns] = useState([
        { field: 'name', header: 'Device Model' },
        {header: 'Device Brand', body:renderdeviceBrand },

    ]);//to be used for DataListing

    useEffect(() => {
        fetchDeviceModels();
    }, []);

    const fetchDeviceModels = async () => {
        try {
            const response = await getDeviceModels();
            setDeviceModels(response);
        } catch (error) {
            console.error('Failed to fetch deviceModels', error);
        }
    };

    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleDelete = async (id) => {
        try {
            await deleteDeviceModel(id);
            fetchDeviceModels();
        } catch (error) {
            console.error('Failed to delete Device Model', error);
        }
    };

    const handleEdit = (rowData) => {
        setIsEdit(true);
        // console.log("deviceModels rowData", rowData);
        setDeviceModelData(rowData);
        setIsModalVisible(true);
    };

    const handleCreate = () => {
        setIsEdit(false);
        setDeviceModelData({
            name: '',
            deviceType: '',
        });
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        try {
            let response = {}
            if (isEdit) {
                response = await updateDeviceModel(deviceModelData.id, deviceModelData);
            } else {
                response = await createDeviceModel(deviceModelData);
            }
            // console.log(response.data);
            responseAlert(response, 300);
            fetchDeviceModels(); // Refresh the list after submission
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error saving Device Model', error);
        }
    };

    return (
        <>
            <DataListing
                data={deviceModels}
                subject={"Device Model"}
                columns={columns}
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete} />
            <Dialog
                header="Device Model"
                visible={isModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsModalVisible(false)}>
                <SingleDeviceModel
                    deviceModel={deviceModelData}
                    setDeviceModel={setDeviceModelData}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                />
            </Dialog>
        </>
    );
};

export default DeviceModelsList;
