// utils/permissions.js
export function hasPermission(userPermissions, subject, action) {
    
    // console.log("pemrission and actions ",userPermissions[0].action)
    // console.log("pemrission and actions ",userPermissions, action, subject)
    if (userPermissions[0]?.action == 'manage' && userPermissions[0]?.subject == 'all')
        return true
    return userPermissions.some(permission =>
        permission.subject === subject && permission.action === action
    );
    
}