import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { createChild, getChildren, updateChild, deleteChild } from '../../api/childApi';
import { getServices } from '../../api/serviceApi';
import { getUsers } from '../../api/userApi';
import SingleChild from './SingleChild';
import DataListing from '../Common/DataListing';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { formatDate } from '../../utils/dateutils';


const ChildrenList = () => {

    const [children, setChildren] = useState([]);
    const [childData, setChildData] = useState({
        fullName: '',
        dateOfBirth: null,
        gender: '',
        diagnosis: '',
        fatherName: '',
        fatherContact: '',
        motherName: '',
        motherContact: '',
        fatherOccupation: '',
        motherOccupation: '',
        address: '',
        familySystem: '',
        siblings: 0,
        birthOrder: '',
        birthHistory: '',
        currentMedications: '',
        allergies: '',
        mainConcerns: '',
        recommendation: '',
        hearingAge: '',
        dateOfHLDiagnosis: null,
        causeOfHL: '',
        hlRightEar: '',
        hlLeftEar: '',
        dateOfHAUse: null,
        isHLProgressive: '',
        haBrandRightEarId: null,
        haBrandLeftEarId: null,
        haModelRightEarId: null,
        haModelLeftEarId: null,
        dateOfCIRightEar: null,
        dateOfCILeftEar: null,
        CIBrandId: null,
        CIModelId: null,
        chosenModeOfCommunication: '',
        nativeLanguage: '',
        isGoingSchool: false,
        grade: '',
        languageSpokenInSchool: ''

    });  //to be used for SingleChild (Add/Edit)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isChildTherapistsModalVisible, setIsChildTherapistsModalVisible] = useState(false);
    const [allTherapists, setAllTherapists] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedChildTherapists, setselectedChildTherapists] = useState([]);
    // console.log("selectedChildTherapists",selectedChildTherapists);
    const renderParentName = (rowData) => {
        return (<span>{rowData.parent.name}</span>)
    };

    const renderDate = (fieldName) => {
        return (rowData) => {
            return <span>{formatDate(rowData[fieldName])}</span>;
        };
    };

    const [columns, setColumns] = useState([
        { field: 'fullName', header: 'Child Name' },
        { header: 'Date of Birth', body: renderDate("dateOfBirth") },
        { field: 'hearingAge', header: 'Hearing Age' },
        { header: 'Parent', body: renderParentName },

    ]);//to be used for DataListing

    useEffect(() => {
        fetchChildren();
        fetchServices();
        fetchTherapists();
        // console.log(selectedChildTherapists.length);
        
    }, []);

    const fetchChildren = async () => {
        try {
            const response = await getChildren();
            console.log("Child Data", response)
            setChildren(response);
        } catch (error) {
            console.error('Failed to fetch children', error);
        }
    };

    const fetchServices = async () => {
        try {
            const response = await getServices();
            setServices(response);
        } catch (error) {
            console.error('Error fetching services', error);
        }
    };
    const fetchTherapists = async () => {
        try {
            const users = await getUsers();
            const therapists = users.filter(user => user.roleId === 4)
            setAllTherapists(therapists);
            // console.log("users",users);
            // console.log("allTherapists",allTherapists);
        } catch (error) {
            console.error('Error fetching services', error);
        }
    };

    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleDelete = async (id) => {
        try {
            await deleteChild(id);
            fetchChildren();
        } catch (error) {
            console.error('Failed to delete children', error);
        }
    };

    const handleEdit = (rowData) => {
        setIsEdit(true);
        // console.log("children rowData", rowData);
        setChildData(rowData);
        setIsModalVisible(true);
    };

    const handleCreate = () => {
        setIsEdit(false);
        // setChildData({
        //     name: '',
        //     age: '',
        // });
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        try {
            let response = {}
            if (isEdit) {
                response = await updateChild(childData.id, childData);
            } else {
                response = await createChild(childData);
            }
            // console.log(response.data);
            responseAlert(response, 300);
            fetchChildren(); // Refresh the list after submission
            // setIsModalVisible(false);
        } catch (error) {
            console.error('Error saving Child Data', error);
        }
    };

    const addTherapistRecord = () => {
        setselectedChildTherapists([...selectedChildTherapists, { service: null, therapist: null }]);
    };
    const removeServiceRow = (index) => {
        if (selectedChildTherapists.length > 1) {
            const updatedChildTherapists = [...selectedChildTherapists];
            updatedChildTherapists.splice(index, 1);
            setselectedChildTherapists(updatedChildTherapists);
            setChildData(prevData => ({ ...prevData, childTherapists: updatedChildTherapists }));
        }
    };

    const handleServiceChange = (serviceId, index) => {
        const updatedChildTherapists = [...selectedChildTherapists];
        const service =services.find(service => service.id === serviceId);
        updatedChildTherapists[index] = { ...updatedChildTherapists[index], service };
        // console.log("on change service",service);
        setselectedChildTherapists(updatedChildTherapists);
        setChildData(prevData => ({ ...prevData, childTherapists: updatedChildTherapists }));
    };
    const handleTherapistChange = (therapistId, index) => {
        const updatedChildTherapists = [...selectedChildTherapists];
        const therapist =allTherapists.find(therapist => therapist.id === therapistId);
        updatedChildTherapists[index] = { ...updatedChildTherapists[index], therapist };
        setselectedChildTherapists(updatedChildTherapists);
        setChildData(prevData => ({ ...prevData, childTherapists: updatedChildTherapists }));
       
    };
   
    const updateChildTherapists = async () => {
     
        let response = await updateChild(childData.id, childData); // Update the user
        responseAlert(response, 300);
        setIsChildTherapistsModalVisible(false);
        fetchChildren();
    };

    const handleChildTherapists = async (rowData) => {
        setChildData(rowData)
        setselectedChildTherapists(rowData.childTherapists)
        if (rowData.childTherapists.length === 0) {
            addTherapistRecord();
        }
        setIsChildTherapistsModalVisible(true);
    };

    const childTherapistsAction = [
        {
            // icon: 'pi pi-user-edit',
            label: 'Child Therapists',
            className: 'btn-outline-primary',
            handler: handleChildTherapists,
            condition: (rowData) => true
        }
    ];
    return (
        <>
            <DataListing
                data={children}
                subject={"Child"}
                columns={columns}
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete}
                extraActions={childTherapistsAction}
            />
            <Dialog
                header="Child Details"
                visible={isModalVisible}
                style={{ width: '50vw' }}
                onHide={() => setIsModalVisible(false)}>
                <SingleChild
                    child={childData}
                    setChild={setChildData}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                />
            </Dialog>
            <Dialog
                header="Child Therapists"
                visible={isChildTherapistsModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsChildTherapistsModalVisible(false)}>

                <div className="form-group">
                    <div className="p-field">
                        {selectedChildTherapists.map((therapistEntry, index) => (
                            <div key={index} className="grid p-align-center">
                                 {/*  full-width */}
                                <div className="col-6">
                                 <label style={{ display: 'block', marginBottom: '0.5rem' }}>Service</label>
                                    <Dropdown
                                        value={therapistEntry.service?.id}
                                        options={services}
                                        onChange={(e) => handleServiceChange(e.value, index)}
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Select a Service"
                                        className='full-width'
                                    />
                                </div>
                                {/* mt-2 mb-1 ml-1 full-width */}
                                <div className="col-6 ">
                                <label style={{ display: 'block', marginBottom: '0.5rem' }}>Therapist</label>
                                    <Dropdown
                                        value={therapistEntry.therapist?.id}
                                        options={allTherapists.length ? allTherapists : []}
                                        onChange={(e) => handleTherapistChange(e.value, index)}
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Select a Therapist"
                                        className='full-width'
                                    />
                                </div>
                                <div className="col-12 p-d-flex p-jc-center p-ai-center" style={{ float: 'right' }}>
                                    <Button
                                        icon="pi pi-plus"
                                        className="p-button-sm p-button-rounded p-button-success"
                                        onClick={addTherapistRecord}
                                        style={{ marginRight: '5px' }}
                                    />
                                    <Button
                                        icon="pi pi-minus"
                                        className="p-button-sm p-button-rounded p-button-danger"
                                        onClick={() => removeServiceRow(index)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='mt-2'>
                        <Button label="Submit" className='full-width btn-primary' icon="pi pi-check" onClick={() => updateChildTherapists()} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ChildrenList;
