// src/components/ResetPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ResetPassword() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/reset-password', { token, newPassword });
      console.log(response.data);
      if(response.data.success){
        toast.success(response.data.msg, { autoClose: 2000 });
        navigate('/login');
      }else{
        toast.error(response.data.msg, { autoClose: 2000 });
      }
     
    } catch (error) {
      // toast.error(error.response.data.msg, { autoClose: 2000 });
      setMessage(error.response.data.msg || 'Error resetting password');
    }
  };

  return (
    <div className="form-container">
      <div className="form-box">
        <Link to="/">
          <img
            alt="logo"
            src="/logo.png"
            height="60"
            className="logo"
          />
        </Link>
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <Password
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className='full-width'
          />
          <Button label="Reset Password" className="btn-secondary" style={{ width: '100%', marginTop: "0.3rem" }} />

        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default ResetPassword;