import axios from 'axios';

const API_URL = '/api/payments';

export const createPayment = async (paymentData) => {
    console.log("paymentData",paymentData);
    const response = await axios.post(API_URL, paymentData);
    return response.data;
};

export const getPayments = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getPaymentsBySubscription = async (subscriptionId) => {
    const response = await axios.get(`${API_URL}/${subscriptionId}`);
    return response.data;
};

export const updatePayment = async (id, paymentData) => {
    const response = await axios.put(`${API_URL}/${id}`, paymentData);
    return response.data;
};

export const deletePayment = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const isPaidAlreadyForCurrentMonth = async (paymentData) => {
    const response = await axios.post(`${API_URL}/isPaidAlreadyForCurrentMonth`, paymentData);
    return response.data;
};
