import axios from 'axios';

const API_URL = '/api/devicebrands';

export const createDeviceBrand = async (deviceBrandData) => {
    const response = await axios.post(API_URL, deviceBrandData);
    return response.data;
};

export const getDeviceBrands = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateDeviceBrand = async (id, deviceBrandData) => {
    const response = await axios.put(`${API_URL}/${id}`, deviceBrandData);
    return response.data;
};

export const deleteDeviceBrand = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};
