// src/components/ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner
    try {
      const response = await axios.post('/api/auth/forgot-password', { email });
      // setMessage(response.data.msg);
      if (response.data.success) {
        toast.success(response.data.msg, { autoClose: 2000 });
      } else {
        toast.error(response.data.msg, { autoClose: 2000 });
      }
    } catch (error) {
      setMessage(error.response.data.msg || 'Error sending reset email');
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <div className="form-container">
      <div className="form-box">
        <Link to="/">
          <img
            alt="logo"
            src="/logo.png"
            height="60"
            className="logo"
          />
        </Link>
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <InputText
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <Button label="Send Reset Link" className="btn-secondary mt-2" style={{ width: '100%' }} />
          </div>
          {loading && (
            <div style={spinnerContainerStyle}>
              <ProgressSpinner />
            </div>
          )}
        </form>
        {message && <p>{message}</p>}
        <div className="login-options">
          <Link to="/login">Go Back to Login</Link>
        </div>
      </div>
    </div>
  );
}
const spinnerContainerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
};

export default ForgotPassword;