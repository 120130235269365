// LoginForm.js
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link,useNavigate  } from 'react-router-dom';
import axios from 'axios';
import '../../styles/login.scss'
import '../../styles/common.scss'
import { toast } from 'react-toastify';


const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // try {
    //   const res = await axios.post('/api/auth/login', formData);
    //   toast.success('Login successful!', { autoClose: 2000 });
    //   <Navigate to="/index"/>
    //   // history.push('/dashboard');
    //   console.log(res.data);
    // } catch (err) {
    //   console.error(err.response.data);
    //   toast.error('Login failed. Please check your credentials.', { autoClose: 2000 });
    // }
    login(formData).then(response => {
      // console.log("response", response);
      if (response.success) {
        toast.success(response.msg, { autoClose: 2000 });
        navigate('/index');
      } else {
        // toast.error('Login failed. Please check your credentials.', { autoClose: 2000 });
        toast.error(response.msg, { autoClose: 2000 });
      }

    });
    // console.log('Form Data:', formData);
  };

  return (
    <div className="form-container">
      <div className="form-box">
        <Link to="/">
          <img
            alt="logo"
            src="/logo.png"
            height="60"
            className="logo"
          />
        </Link>
        {/* <h2>Login</h2> */}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              required
              className='full-width'
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Password
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter your password"
              required
              className='full-width'
            />
          </div>
          <Button label="Login" className="btn-secondary" style={{ width: '100%' }} />
        </form>
        <div className="login-options">
          <Link to="/forgot-password">Forgot Password?</Link>
          <span> | </span>
          <Link to="/register">Register</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;