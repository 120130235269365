import React, { useState,useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { editUser,getUserById } from '../../api/userApi'; // Import API functions
import { toast } from 'react-toastify'
import PersonalDetails from './PersonalDetails';
import { Button } from 'primereact/button';

const UserProfile = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState({
    name: '',
    phone: '',
    address: ''
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getUserById(user.id);
        // console.log(response);
        setUserDetails(response);
      } catch (error) {
        console.error('Failed to fetch user details', error);
      }
    };

    if (user && user.id) {
      fetchUserDetails();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("updated user", userDetails);
  };

  const handleSubmit = async () => {

    const response = await editUser(user.id, userDetails); // Update the user
    if (response.success) {
      toast.success(response.message, { autoClose: 200 })
    }
    else {
      toast.error(response.message, { autoClose: 200 })
    }
    console.log("response", response)

  };

  return (
    <div className='flex align-items-center justify-content-center'>
      <div className="form-box" style={{ width: '30vw' }}>
        <h2>Update Profile</h2>
        <PersonalDetails userDetails={userDetails} handleChange={handleChange}
        />
        <Button
          label="Save"
          className='btn-primary mt-2'
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default UserProfile;
