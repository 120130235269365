// src/components/Settings.js
import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { toast } from 'react-toastify';

// console.log("settings view");
const Settings = () => {
    const [settings, setSettings] = useState({ emailServer: '', emailPort: '', emailUser: '', emailPassword: '' });
    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        // Fetch settings from the server
        axios.get('/api/settings')
            .then(response => setSettings(response.data))
            .catch(error => console.error(error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings({ ...settings, [name]: value });
    };

    const handleSave = () => {
        // Save settings to the server
        setLoading(true); // Show spinner
        axios.post('/api/settings', settings)
            .then(response => {
                if (response.data.success) {
                    toast.success(response.data.msg, { autoClose: 2000 });
                } else {
                    toast.error(response.data.msg, { autoClose: 2000 });
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                setLoading(false); // Hide spinner
            });
    };
    const testEmailConnection = () => {
        setLoading(true); // Show spinner
        axios.post('/api/settings/testEmail', settings)
            .then(response => {
                if (response.data.success) {
                    toast.success(response.data.msg, { autoClose: 2000 });
                } else {
                    toast.error(response.data.msg, { autoClose: 2000 });
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                setLoading(false); // Hide spinner
            });

    }
    // console.log(settings);
    return (
        <div>
            <h2>Settings</h2>
            <TabView>
                <TabPanel header="Email Settings">

                    <div className="form-group">
                        <label htmlFor="emailServer">Email Server</label>
                        <InputText id="emailServer" name="emailServer" value={settings.emailServer} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailPort">Email Port</label>
                        <InputText id="emailPort" name="emailPort" value={settings.emailPort} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailUser">Email User</label>
                        <InputText id="emailUser" name="emailUser" value={settings.emailUser} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailPassword">Email Password</label>
                        <InputText id="emailPassword" name="emailPassword" type="password" value={settings.emailPassword} onChange={handleChange} />
                    </div>
                    <Button className='btn-secondary' label="Test Connection" onClick={testEmailConnection} />

                </TabPanel>
                <TabPanel header="App Settings"></TabPanel>
            </TabView>
            <Button className='btn-primary' style={{ float: 'right' }} label="Save Settings" onClick={handleSave} />
            {loading && (
                <div style={spinnerContainerStyle}>
                    <ProgressSpinner />
                </div>
            )}
        </div>
    );
};
const spinnerContainerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
};

export default Settings;
