import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { toast } from 'react-toastify';
import { fetchUserPermissions } from '../../api/userApi';
import { hasPermission } from '../../utils/permissions';

import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const DataListing = ({ data, columns, onCreate, onEdit, onDelete, subject , extraActions = []}) => {

    const [permissions, setPermissions] = useState([]);
    const { user } = useAuth();
    useEffect(() => {
        const getPermissions = async () => {
            try {
                const userPermissions = await fetchUserPermissions(user.id);
                setPermissions(userPermissions);
            } catch (error) {
                console.error('Error fetching permissions:', error);
            }
        };

        getPermissions();
    }, []);
    const exportCSV = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'data.xlsx');
    };

    const handleDelete = (recordId) => {
        confirmDialog({
            message: 'Are you sure you want to delete this record?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                onDelete(recordId);
                // toast.success('Record deleted', { autoClose: 2000 });
            },
            reject: () => {
                toast.info('You have cancelled', { autoClose: 2000 });
            }
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {(onCreate && hasPermission(permissions, subject, "create"))&&
                    (<Button label="Add New" icon="pi pi-plus" style={{ marginRight: '0.2rem' }} className="btn-primary" onClick={onCreate} />)
                }
                <Button label="Export" icon="pi pi-upload" className="btn-secondary" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const columnFilterTemplate = (options) => {
        return (
            <span className="p-input-icon-left">
                {/* <i className="pi pi-search" /> */}
                <input
                    type="text"
                    value={options.value}
                    onChange={(e) => options.filterCallback(e.target.value)}
                    className="p-inputtext p-component"
                    placeholder="Search"
                />
            </span>
        );
    };

    const renderExtraActions = (rowData) => (
        extraActions.map((action, index) => (
            action.condition(rowData) &&
            <Button
                key={index}
                icon={action.icon}
                label={action.label}
                className={action.className}
                onClick={() => action.handler(rowData)}
                style={{ marginRight: "0.2rem" }}
            />
        ))
    );

    return (
        <div>
            <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
            <DataTable value={data} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} resizableColumns columnResizeMode="fit">
                {columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.body}
                        filter
                        filterField={col.field}
                        filterPlaceholder={`Search ${col.header}`}
                        filterMatchMode="contains"
                        filterElement={columnFilterTemplate}
                    />
                ))}
                <Column
                    header="Actions"
                    body={(rowData) => (
                        <div>
                            {renderExtraActions(rowData)}
                            {hasPermission(permissions, subject, "update") &&
                                <Button icon="pi pi-pencil" style={{ marginRight: "0.2rem" }} className="p-button-rounded p-button-info" onClick={() => onEdit(rowData)} />
                            }
                            {hasPermission(permissions, subject, "delete") &&
                                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => handleDelete(rowData.id)} />
                            }
                            
                        </div>
                    )}
                />
            </DataTable>
            <ConfirmDialog />
        </div>
    );
};

export default DataListing;
